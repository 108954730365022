function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}
function setCookie(name, value, days) {
  if (days === undefined || days === null) {
    days = 1;
  }
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  let secure = location.protocol === 'https' ? ';Secure' : '';
  let sameSite = ';SameSite=Lax';
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString() + sameSite + secure;
}
function deleteCookie(name) {
  setCookie(name, '', -1);
}

export default {
  getCookie,
  setCookie,
  deleteCookie,
};
