import * as ttag from 'ttag';

import cookie from './cookie';

// import * as i18n from 'i18n/*.po';

const LOCALE_COOKIE = '__locale';

export function getLocale() {
  return cookie.getCookie(LOCALE_COOKIE) || 'en';
}

export function saveLocale(locale) {
  cookie.setCookie(LOCALE_COOKIE, locale);
}

export async function switchLocale(newLocale) {
  if (newLocale !== 'en') {
    // load json file with translations
    const translationsObj = await fetch(i18n[newLocale]).then(r => r.json());
    ttag.addLocale(newLocale, translationsObj);
  }
  ttag.useLocale(newLocale);
}
